#search-results {
  display: grid;
  grid-template-areas: 'facets' 'result-text' 'hits' 'pagination';

  .card {
    width: 100%;
  }
}

.ais-Hits-list {
  grid-area: hits;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1rem;
  list-style: none;
  margin: 0 0 1rem;
}

.ais-Pagination {
  grid-area: pagination;
}

.result-text {
  grid-area: result-text;
}

.facets {
  grid-area: facets;
}

.ais-Hits-item {
  padding: 0;
  box-shadow: none;
}

.card-figcaption--action-buttons {
  gap: 1rem;
}

.quickview {
  margin: 0;
}

.ais-NumericMenu-label,
.ais-ToggleRefinement-label,
.ais-HierarchicalMenu-item,
.ais-Menu-item,
.ais-RatingMenu-item,
.ais-RefinementList-item,
.ais-Hits-item,
.ais-InfiniteHits-item {
  font-size: 1rem;
}

.facets h5:first-of-type {
  margin-top: 0;
}

.ais-SearchBox-input {
  display: none;
}

.ais-Panel-header {
  text-transform: none;
  font-size: 1.25rem;
  color: #4e4e4e;
}

.ais-Panel--noRefinement {
  display: none;
}

@media (min-width: 801px) {
  #search-results {
    gap: 1rem;
    grid-auto-flow: row;
    grid-template-columns: 1fr 3fr;
    grid-template-areas: 'result-text result-text' 'facets hits' 'facets pagination';
  }
}
