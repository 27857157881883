:root {
  --aa-primary-color-rgb: 219, 43, 47;
  --aa-primary-color-alpha: 0.2;
  --aa-input-border-color-rgb: 26, 90, 125;
  --aa-font-size: 14px;
  --aa-font-family: 'Roboto Condensed';
  --aa-custom-desktop-width: 400px;
  --aa-input-icon-size: 28px;
}

.aa-Panel,
.full-variant-list-wrapper {
  margin-top: 0;
  border-radius: 0;
}

.aa-PanelLayout {
  display: grid;
  gap: 1rem;
  max-height: var(--search-panel-height);
}

.aa-ItemIcon {
  --aa-icon-size: 50px;
}

.aa-ItemIcon--large {
  --aa-icon-size: 80px;
}

.aa-SourceHeader {
  font-weight: bold;
  color: #1a5a7d;
}

.aa-Item {
  min-height: unset;
}

.aa-SourceFooter {
  margin-top: 0.75rem;
}

.see-more {
  display: flex;
  width: 100%;
}

.aa-SourceFooter a,
.see-more a {
  font-style: italic;
  color: #38b4e5 !important;
}

.preview-title {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content 1fr;
  gap: 1rem;
  margin-bottom: 1.25rem;

  &-text {
    align-self: center;
    font-size: 1.25rem;
    font-weight: 500;
  }
}

.aa-ItemContentTitle {
  color: #222;

  &--wrap {
    white-space: unset;
    text-overflow: unset;
  }
}

.preview-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.25;
}

.variant-skus {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.sku-badge, .variant-skus > li {
  display: inline-block;
  padding: .25em .5em;
  font-size: .75em;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  background-color: #38b4e5;
  text-align: center;
  white-space: nowrap;
  vertical-align: text-top;
  border-radius: .25rem;

  &.with-name-hit {
    background-color: #1a5a7d;
  }
}

.product-results {
  .aa-List {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .aa-Item {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px -1px rgba(0,0,0,0.1);
    border: 1px solid rgba(var(--aa-selected-color-rgb), 0.25);
  }
}

.group-variants {
  display: grid;
  gap: 0.25rem;
  list-style: none;
  margin-left: 0;
  font-family: 'Roboto Condensed';

  li {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.product-title {
  font-size: 1.125rem;
  font-weight: bold;
}

.panel {
  &--products-categories {
    grid-area: products-and-categories;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &--pages-and-blogs {
    grid-area: pages-and-blogs;
    display: flex;
    border-top: 1px solid #1a5a7d;
    margin-top: 1rem;
  }

  &--preview {
    grid-area: preview;
  }
}

.aa-InputWrapperSuffix {
  display: none;
}

.aa-Input {
  text-align: right;
}

.aa-Form {
  --aa-primary-color-rgb: 56, 180, 229;
  --aa-primary-color-alpha: 0.75;

  flex-direction: row-reverse;
  border: none;
  border-radius: 0;
}

.aa-Autocomplete {
  position: relative;
}

.aa-Panel--desktop {
  right: 0 !important;
}

.aa-Form:has(.aa-Input:focus) {
  svg {
    color: #ba0c10;
  }

  .aa-InputWrapperSuffix {
    display: flex;
  }
}

.aa-SubmitButton {
  --aa-primary-color-rgb: 51, 51, 51;
  padding: 0 !important;
}

.aa-ItemContent mark {
  background-color: yellow;
}

.full-variant-list-wrapper {
  position: fixed;
  max-width: var(--aa-custom-desktop-width);
  padding: 0.75rem 0.5rem;
  background-color: rgba(var(--aa-background-color-rgb), var(--aa-background-color-alpha));
  box-shadow: var(--aa-panel-shadow);
  overflow-y: auto;
  width: 100%;
  max-height: var(--search-panel-height);

  .close-variant-list-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    right: 0;
    height: 32px;
  }

  .aa-List {
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
  }

  scrollbar-color: white #eaeaea;
  scrollbar-color: rgba(var(--aa-scrollbar-thumb-background-color-rgb), var(--aa-scrollbar-thumb-background-color-alpha)) rgba(var(--aa-scrollbar-track-background-color-rgb), var(--aa-scrollbar-track-background-color-alpha));
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 13px;
    width: var(--aa-scrollbar-width);
  }

  &::-webkit-scrollbar-track {
    background-color: #eaeaea;
    background-color: rgba(var(--aa-scrollbar-track-background-color-rgb), var(--aa-scrollbar-track-background-color-alpha));
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    background-color: rgba(var(--aa-scrollbar-thumb-background-color-rgb), var(--aa-scrollbar-thumb-background-color-alpha));
    border-color: #eaeaea;
    border-color: rgba(var(--aa-scrollbar-track-background-color-rgb), var(--aa-scrollbar-track-background-color-alpha));
    border-radius: 9999px;
    border-style: solid;
    border-width: 3px 2px 3px 3px;
  }
}

@media (min-width: 801px) {
  .aa-Panel {
    --searchbar-offset-top: 126px;
    --aa-panel-max-height: 750px;

    bottom: unset;
    width: 100%;
    max-width: var(--aa-custom-desktop-width);
    max-height: 700px;
    overflow: visible;
    margin-left: 0.25rem;
    margin-top: 0.25rem;
  }

  .full-variant-list-wrapper {
    position: absolute;
    right: calc(0.25rem + var(--aa-custom-desktop-width));
    max-height: var(--aa-panel-max-height);
  }

  .aa-Panel,
  .full-variant-list-wrapper {
    border-radius: calc(var(--aa-spacing) / 4);
  }

  .aa-PanelLayout {
    margin: auto;
    gap: 1rem;
    overflow: visible;
    max-height: var(--aa-panel-max-height);
  }
}
